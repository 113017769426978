import React from "react";
import styled from "@emotion/styled"

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import { Template } from "@narative/gatsby-theme-novela/src/types";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

import Body from '../components/Body'

const Header = styled.h2`
    font-size: 2em;
    margin-bottom: 50px;
`

const CVHeader = styled.h3`
    font-size: 1.33em;
`

const Url = styled.div`
    font-size: 0.8em;
    opacity: 0.7;
`

const Years = styled.div`
    margin: 3px 0px;
    font-size: 0.66em;
    opacity: 0.5;
`

const MainDiv = styled(Body)`
  ${mediaqueries.desktop_medium_up`
    font-size: 1.33em;
  `}

  ${mediaqueries.tablet`
    font-size: 1em;
  `}

  ${mediaqueries.phablet`
    font-size: 1em;
  `}
`

const ProjPart = ({project, years, url, text, skills}) => {
    return <div style={{marginBottom: '70px'}}>
        <CVHeader>{project}</CVHeader>
        {url ? <Url><a href={url}>{url}</a></Url> : null}
        <Years>{years}</Years>

        <br />
        {text}
        <br />
        <div style={{fontSize: '0.7em'}}><b>Technologies:</b> {skills}</div>
    </div>
}

const ProjectsPage: Template = ({ location, pageContext }) => {
  return (
    <Layout>
      <SEO pathname={location.pathname} />
      <Section>
        <MainDiv>
            <Header>Projects</Header>

            <div style={{marginBottom: '50px'}}>
                Here is a list of projects I made over the years:
            </div>

            <ProjPart
            project="Flashhost"
            years="June 2020-March 2021 (done in 9 months)"
            text={<div><div>
                Flashhost was a static site hosting/publishing platform that makes it
                very easy to deploy from terminal with one command.</div>
                <br />

                <div>After focusing on finishing projects in 2 months,
                I found out that even if you finish a project fast, deployment is still hard work. This project helped me
                immensely in becoming a better devops engineer.</div>
                <br />

                <div>The project was closed due to lack of interest and my mistake in understanding the product/market fit. A good lesson.</div>
            </div>}
            skills={<span>GraphQL, NodeJS, migra, Postgraphile, Docker, Ansible</span>}
              ></ProjPart>

            <ProjPart
            project="This very website"
            years="March 2021 (done in 3 days)"
            text={<div>
                This website is a result of quick tinkering with GatsbyJS and awesome <a href="https://www.narative.co/labs/novela/">Novela</a> theme
              </div>}
            skills={<span>Gatsby</span>}
              ></ProjPart>

            <ProjPart
            project="Tertia"
            years="2019-2020 (never finished)"
            url="https://bitbucket.org/danganiev/landing-constructor/"
            text={<div>
                Tertia was a block-based website constructor, which was supposed to rival even <a href="https://tilda.cc">Tilda</a>.
                But again, it was too ambitious for one developer. This time I detected it much earlier though,
                so I have been able to drop the project before I lost too much to sunk costs. From this point on I decided
                that solo projects must not take more than two months.
              </div>}
            skills={<span>GraphQL, NodeJS, <a href="https://prisma.io" target="_blank">Prisma</a></span>}
              ></ProjPart>

            <ProjPart
            project="Worlds"
            years="2010-2014 (never finished)"
            url="https://github.com/danganiev/worldsgame"
            text={<div>
                Worlds was a very ambitious Minecraft-like game, which sadly died due to being <i>too </i>
                ambitious for one developer. If you imagine Minecraft as a big purchased LEGO package, then my
                game was a LEGO factory. You could make your own blocks/block distribution rules/recipes/NPCs and
                so on. After all these years I still like the idea, but the game is too
                complex to finish for anything less than a big team. It is now open-sourced.
              </div>}
            skills="C#, XNA/Monogame, generic gamedev math"
              ></ProjPart>
        </MainDiv>
      </Section>
    </Layout>
  );
};

export default ProjectsPage;


